import { React, useState, useEffect, useRef } from "react";
import Home from "../../assets/icons/discovery.svg";
import Explore from "../../assets/icons/sidebar_explore.svg";
import Profile from "../../assets/icons/profile.svg";
import EmptyProfile from "../../assets/icons/empty_profile_icon.svg";
import Saved from "../../assets/icons/sidebar_saved.svg";
import Favorite from "../../assets/icons/favorite.svg";
import Category from "../../assets/icons/category.svg";
import Settings from "../../assets/icons/setting.svg";
import Discord from "../../assets/icons/discord.svg";
import SignOut from "../../assets/icons/sign_out.svg";
import SignIn from "../../assets/icons/Sign_in.svg";
import Menu from "../../assets/icons/menu.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { clearProfileData } from "./../../redux/slices/profileSlice";
import { clearItems } from "./../../redux/slices/profileItemsSlice";
import { setLoginMode } from "./../../redux/slices/modalSlice";
import useModal from "./../hooks/ModalHook";
import { closeSidebar } from "./../../redux/slices/uiSlice";
import { domainUrl } from "./../../utils/globals";
import Logo from "../../assets/images/chips_logo.svg";

const Sidebar = ({ footerRef }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isLogin = useSelector((state) => state.auth.isLoggedIn);
  const [showCommunityButton, setShowCommunityButton] = useState(false);
  const auth = useSelector((state) => state.auth);
  const myData = useSelector((state) => state.myData);
  const dispatch = useDispatch();
  const { handleOpenModal } = useModal();
  const communityButtonRef = useRef(null);
  const sidebarRef = useRef(null);
  const [adminEmail, setAdminEmail] = useState(["meenakshi@chips.social"]);
  const isSidebarOpen = useSelector((state) => state.ui.isSidebarOpen);
  const [isSidebarShrinked, setIsSidebarShrinked] = useState(false);

  useEffect(() => {
    if (!footerRef.current) return;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsSidebarShrinked(true);
        } else {
          setIsSidebarShrinked(false);
        }
      },
      {
        root: null, // Use the viewport as the root
        threshold: 0.1, // Adjust as needed
      }
    );

    observer.observe(footerRef.current);
    return () => {
      observer.disconnect();
    };
  }, [footerRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebarBounds = sidebarRef.current?.getBoundingClientRect();
      const communityButtonBounds =
        communityButtonRef.current?.getBoundingClientRect();

      if (isSidebarOpen) {
        if (
          sidebarBounds &&
          (event.clientX < sidebarBounds.left ||
            event.clientX > sidebarBounds.right ||
            event.clientY < sidebarBounds.top ||
            event.clientY > sidebarBounds.bottom) &&
          (!communityButtonBounds ||
            event.clientX < communityButtonBounds.left ||
            event.clientX > communityButtonBounds.right ||
            event.clientY < communityButtonBounds.top ||
            event.clientY > communityButtonBounds.bottom)
        ) {
          closeSidebarHandler();
        }
      }

      const isClickInsideCommunityButton = communityButtonRef.current?.contains(
        event.target
      );

      if (showCommunityButton && !isClickInsideCommunityButton) {
        setShowCommunityButton(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSidebarOpen, showCommunityButton]);

  const handleLogoutOpen = () => {
    handleOpenModal("modalLogoutOpen");
  };
  const handleLoginOpen = () => {
    dispatch(setLoginMode(true));
    handleOpenModal("modalLoginOpen");
  };

  const handleSavedBy = () => {
    closeSidebarHandler();
    if (isLogin) {
      const hostnameParts = window.location.hostname.split(".");
      const isSubdomain =
        hostnameParts.length > 2 && hostnameParts[0] !== "www";
      if (isSubdomain) {
        window.open(
          `https://${domainUrl}/saved/${auth.user._id}/curations`,
          "_blank"
        );
      } else {
        navigate(`/saved/${auth.user._id}/curations`);
      }
    } else {
      handleOpenModal("modalLoginOpen");
    }
  };
  const handleProfile = () => {
    closeSidebarHandler();
    if (isLogin) {
      const hostnameParts = window.location.hostname.split(".");
      const isSubdomain =
        hostnameParts.length > 2 && hostnameParts[0] !== "www";
      if (isSubdomain) {
        window.open(
          `https://${domainUrl}/profile/${auth.user.username}`,
          "_blank"
        );
      } else {
        if (location.pathname !== `/profile/${auth.user.username}`) {
          dispatch(clearProfileData());
          dispatch(clearItems());
        }
        navigate(`/profile/${auth.user.username}`);
      }
    } else {
      dispatch(setLoginMode(true));
      handleOpenModal("modalLoginOpen");
    }
  };

  const handleNavigateHome = () => {
    closeSidebarHandler();
    const hostnameParts = window.location.hostname.split(".");
    const isSubdomain = hostnameParts.length > 2 && hostnameParts[0] !== "www";
    if (isSubdomain) {
      window.open(`https://${domainUrl}`, "_blank");
    } else {
      navigate("/");
    }
  };

  const openAdminPanel = () => {
    navigate("/bugs");
  };
  const toggleCommunityButton = (event) => {
    event.stopPropagation();
    setShowCommunityButton((prev) => !prev);
  };

  const isAdmin = adminEmail?.includes(myData.email);

  const closeSidebarHandler = () => {
    dispatch(closeSidebar());
  };

  const handleNewsLetterPage = () => {
    navigate("/newsletter");
  };

  return (
    <>
      {/* <div className="relative z-10">  */}
      <div
        className={`text-white xs:w-14 sm:w-16 pr-2 flex-col py-4 justify-between fixed top-[70px] bg-primaryBackground hidden xs:flex ${
          isSidebarShrinked ? "h-full-height-230 z-50" : "z-50 h-full-height-70"
        }`}
      >
        <div className="flex flex-col flex-1 justify-center items-center space-y-8 mt-8 ">
          {isLogin && (
            <div className="relative group">
              <button
                onClick={handleProfile}
                className={`flex items-center ${
                  myData.logo
                    ? `p-0.5 ${
                        location.pathname === `/profile/${auth.user.username}`
                          ? "border border-white"
                          : ""
                      }`
                    : ""
                } rounded-lg justify-center cursor-pointer`}
              >
                <img
                  src={
                    myData.logo
                      ? myData.logo
                      : location.pathname === `/profile/${auth.user.username}`
                      ? Profile
                      : EmptyProfile
                  }
                  alt="Profile"
                  className="w-6 h-6 rounded-md object-cover"
                />
              </button>
              <div className="absolute -top-7 w-max left-4 transform -translate-x-1/2 bg-dark text-white text-xs rounded-lg px-2 py-1 opacity-0 group-hover:opacity-100">
                Profile
              </div>
            </div>
          )}
          {!isLogin && (
            <div className="relative group">
              <button
                onClick={handleProfile}
                className="flex items-center rounded-lg justify-center cursor-pointer"
              >
                <img
                  src={EmptyProfile}
                  alt="Profile"
                  className="w-6 h-6 rounded-md object-cover"
                />
              </button>
              <div className="absolute -top-7 w-max left-4 transform -translate-x-1/2 bg-dark text-white text-xs rounded-lg px-2 py-1 opacity-0 group-hover:opacity-100">
                Profile
              </div>
            </div>
          )}
          <div className="relative group">
            <button
              onClick={handleNavigateHome}
              className="w-8 h-8 flex items-center justify-center cursor-pointer"
            >
              <img
                src={location.pathname === "/" ? Explore : Home}
                alt="Home"
                className="w-6 h-6"
              />
            </button>
            <div className="absolute -top-5 w-max left-4 transform -translate-x-1/2 bg-dark text-white text-xs rounded-lg px-2 py-1 opacity-0 group-hover:opacity-100">
              Home
            </div>
          </div>
          {isLogin && (
            <div className="relative group">
              <button
                onClick={handleSavedBy}
                className="w-8 h-8 flex items-center justify-center"
              >
                <img
                  src={location.pathname.includes("/saved") ? Favorite : Saved}
                  alt="Saved"
                  className="w-6 h-6"
                />
              </button>
              <div className="absolute -top-5 w-max left-4 transform -translate-x-1/2 bg-dark text-white text-xs rounded-lg px-2 py-1 opacity-0 group-hover:opacity-100">
                Saved
              </div>
            </div>
          )}
          {!isLogin && (
            <div className="relative group">
              <button
                onClick={handleSavedBy}
                className="w-8 h-8 flex items-center justify-center"
              >
                <img src={Saved} alt="Saved" className="w-6 h-6" />
              </button>
              <div className="absolute -top-7 w-max left-4 transform -translate-x-1/2 bg-dark text-white text-xs rounded-lg px-2 py-1 opacity-0 group-hover:opacity-100">
                Saved
              </div>
            </div>
          )}

          {/* {isLogin && (
            <div className="relative group">
              <button
                onClick={handleNewsLetterPage}
                className="w-8 h-8 flex items-center justify-center"
              >
                <img src={Category} alt="Saved" className="w-6 h-6" />
              </button>
              <div className="absolute -top-7 w-max left-4 transform -translate-x-1/2 bg-dark text-white text-xs rounded-lg px-2 py-1 opacity-0 group-hover:opacity-100">
                Newsletter
              </div>
            </div>
          )} */}
        </div>
        <div className="flex flex-col items-center space-y-4 mb-2">
          {isLogin && isAdmin && (
            <button
              className="w-8 h-8 flex items-center rounded-md justify-center"
              onClick={openAdminPanel}
            >
              <img
                src={Settings}
                alt="Settings"
                className="w-6 h-6 rounded-md"
              />
            </button>
          )}
          <div className="relative group">
            <button
              className="w-8 h-8 flex items-center justify-center"
              onClick={toggleCommunityButton}
            >
              <img src={Menu} alt="Menu" className="w-6 h-6" />
            </button>
            <div className="absolute -top-4 w-max left-1/2 transform -translate-x-1/2 bg-dark text-white text-xs rounded-lg px-2 py-1 opacity-0 group-hover:opacity-100">
              Menu
            </div>
          </div>
        </div>
        {showCommunityButton && (
          <div
            ref={communityButtonRef}
            className={`absolute z-[9999] bottom-[50px] w-max left-14 rounded-md shadow-lg px-3 border border-dividerLine bg-chipBackground ring-1 ring-black ring-opacity-5 `}
            style={{ zIndex: 9999 }}
          >
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <a
                href="https://discord.gg/zDKq4QSmQK"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-websiteHomeBox text-textFieldColor text-sm p-2 rounded-md flex items-center space-x-2 shadow-lg transform translate-y-2"
              >
                <img src={Discord} alt="Discord" className="w-6 h-6" />
                <span>Join Community</span>
              </a>
              {isLogin && (
                <div
                  className="flex flex-row pt-3 pb-1 items-center"
                  onClick={handleLogoutOpen}
                >
                  <img src={SignOut} alt="edit" className="w-6 h-6" />
                  <p
                    className="block ml-1 py-2 text-sm text-textFieldColor cursor-pointer"
                    role="menuitem"
                  >
                    Logout
                  </p>
                </div>
              )}
              {!isLogin && (
                <div
                  className="flex flex-row pt-3 pb-1 items-center"
                  onClick={handleLoginOpen}
                >
                  <img src={SignIn} alt="edit" className="w-6 h-6" />
                  <p
                    className="block ml-1 py-2 text-sm text-textFieldColor cursor-pointer"
                    role="menuitem"
                  >
                    Login
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
        {/* </div> */}
      </div>
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-60 z-[9998]"
          onClick={closeSidebarHandler}
        >
          <div
            ref={sidebarRef}
            onClick={(e) => e.stopPropagation()}
            className="text-white w-16 pl-2 fixed inset-0 flex flex-col py-4 justify-between h-full bg-dark z-[9999] xs:hidden sm:flex"
          >
            <img
              src={Logo}
              alt="Chips Logo"
              className="h-8 mr-2 mt-2 cursor-pointer"
              onClick={handleNavigateHome}
            />
            <div className="flex flex-col items-center mt-0 space-y-8 mr-3">
              {isLogin && (
                <button
                  onClick={handleProfile}
                  className={`flex items-center ${
                    myData.logo
                      ? `p-0.5 ${
                          location.pathname === `/profile/${auth.user.username}`
                            ? "border border-white"
                            : ""
                        }`
                      : ""
                  } rounded-lg justify-center cursor-pointer`}
                >
                  <img
                    src={
                      myData.logo
                        ? myData.logo
                        : location.pathname === `/profile/${auth.user.username}`
                        ? Profile
                        : EmptyProfile
                    }
                    alt="Profile"
                    className="w-6 h-6 rounded-md"
                  />
                </button>
              )}
              {!isLogin && (
                <button
                  onClick={handleProfile}
                  className={`flex items-center rounded-lg justify-center cursor-pointer`}
                >
                  <img
                    src={EmptyProfile}
                    alt="Profile"
                    className="w-6 h-6 rounded-md object-cover"
                  />
                </button>
              )}
              <button
                onClick={handleNavigateHome}
                className="w-8 h-8 flex items-center justify-center cursor-pointer"
              >
                <img
                  src={location.pathname === "/" ? Explore : Home}
                  alt="Home"
                  className="w-6 h-6"
                />
              </button>
              {isLogin && (
                <button
                  onClick={handleSavedBy}
                  className="w-8 h-8 flex items-center justify-center"
                >
                  <img
                    src={
                      location.pathname.includes("/saved") ? Favorite : Saved
                    }
                    alt="Saved"
                    className="w-6 h-6"
                  />
                </button>
              )}
              {!isLogin && (
                <button
                  onClick={handleSavedBy}
                  className="w-8 h-8 flex items-center justify-center"
                >
                  <img src={Saved} alt="Saved" className="w-6 h-6" />
                </button>
              )}
            </div>
            <div className="flex flex-col mb-1 items-center space-y-4 mr-1">
              {isLogin && isAdmin && (
                <button
                  className="w-8 h-8 flex items-center rounded-md justify-center"
                  onClick={openAdminPanel}
                >
                  <img
                    src={Settings}
                    alt="Settings"
                    className="w-6 h-6 rounded-md"
                  />
                </button>
              )}
              <button
                className="w-8 h-8 flex items-center justify-center"
                onClick={toggleCommunityButton}
              >
                <img src={Menu} alt="Menu" className="w-6 h-6" />
              </button>
            </div>
            {showCommunityButton && (
              <div
                ref={communityButtonRef}
                className={`absolute bottom-[50px] w-max left-16 xs:left-14 rounded-md shadow-lg px-3 border  border-dividerLine bg-chipBackground ring-1 ring-black ring-opacity-5 z-50`}
              >
                <div
                  className="py-1"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  <a
                    href="https://discord.gg/zDKq4QSmQK"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-websiteHomeBox text-textFieldColor text-sm p-2 rounded-md flex items-center space-x-2 shadow-lg transform translate-y-2"
                  >
                    <img src={Discord} alt="Discord" className="w-6 h-6" />
                    <span>Join Community</span>
                  </a>
                  {isLogin && (
                    <div
                      className="flex flex-row pt-3 pb-1 items-center"
                      onClick={handleLogoutOpen}
                    >
                      <img src={SignOut} alt="edit" className="w-6 h-6" />
                      <p
                        className="block ml-1 py-2 text-sm text-textFieldColor cursor-pointer"
                        role="menuitem"
                      >
                        Logout
                      </p>
                    </div>
                  )}
                  {!isLogin && (
                    <div
                      className="flex flex-row pt-3 pb-1 items-center"
                      onClick={handleLoginOpen}
                    >
                      <img src={SignIn} alt="edit" className="w-6 h-6" />
                      <p
                        className="block ml-1 py-2 text-sm text-textFieldColor cursor-pointer"
                        role="menuitem"
                      >
                        Login
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
