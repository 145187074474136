import { React, useEffect, useRef, Suspense, lazy } from "react";
import MySubscriptions from "./my_subscriptions/subscriptions";
import LeftComponent from "./banner_widgets/Left_banner";
import MiddleComponent from "./banner_widgets/Middle_banner";
import RightComponent from "./banner_widgets/Right_banner";
import HashtagBanner from "./banner_widgets/Hashtag1_banner";
import ProductHunt from "../../assets/images/product_hunt.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCategoryCurations,
  fetchmySubscriptions,
} from "./../../redux/slices/homeSlice";
import { setLoginMode } from "./../../redux/slices/modalSlice";
import useModal from "./../hooks/ModalHook";
import MySubscriptionsSkeleton from "./../skeleton/mySubscriptionsSkeleton";
import HomeCurationSkeleton from "./../skeleton/homeCurationSkeleton";

const CurationList = lazy(() => import("./category/CurationList"));

const Home = ({ onboardClick }) => {
  const scrollRef = useRef(null);
  let isDown = false;
  let startX;
  let scrollLeft;

  const handleMouseDown = (e) => {
    isDown = true;
    scrollRef.current.classList.add("active");
    startX = e.pageX - scrollRef.current.offsetLeft;
    scrollLeft = scrollRef.current.scrollLeft;
  };

  const handleMouseLeave = () => {
    isDown = false;
    scrollRef.current.classList.remove("active");
  };

  const handleMouseUp = () => {
    isDown = false;
    scrollRef.current.classList.remove("active");
  };

  const handleMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogin = useSelector((state) => state.auth.isLoggedIn);

  const { categories, categoriesstatus } = useSelector(
    (state) => state.homeItems
  );
  const { subscriptions, subscriptionstatus } = useSelector(
    (state) => state.homeItems
  );
  const myData = useSelector((state) => state.myData);
  const { handleOpenModal } = useModal();

  const navigateToSubscriptions = () => {
    navigate(`/subscriptions/${myData.username}`);
  };

  const handleLoginModal = () => {
    dispatch(setLoginMode(true));
    handleOpenModal("modalLoginOpen");
  };

  const handleCategoryCurations = (category) => {
    const encodedCategory = encodeURIComponent(category);
    navigate(`/categories/${encodedCategory}`);
  };

  useEffect(() => {
    dispatch(fetchmySubscriptions());
    if (categories.length === 0) {
      dispatch(fetchCategoryCurations());
    }
  }, [dispatch]);

  return (
    <div className=" mt-3 flex flex-col items-start w-full">
      <div className=" flex flex-col w-full ">
        {/* <a
          className="sm:hidden flex border flex-row justify-between bg-white border-productHunt  items-center px-2 py-1.5 rounded-lg mr-4"
          href="https://www.producthunt.com/posts/chips-2?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-chips&#0045;2"
          target="_blank"
          rel="noreferrer"
        >
          <p className=" text-black text-xs xs:text-[13px] font-normal font-inter">
            Support us with an upvote!
          </p>
          <img src={ProductHunt} alt="product-hunt" className="h-6" />
        </a> */}
        {!isLogin && (
          <div className="flex-shrink-0 sm:mt-0 mt-4 ">
            <LeftComponent
              onboardClick={onboardClick}
              onStartedClick={handleLoginModal}
            />
          </div>
        )}
        <div
          className="flex flex-row space-x-4 mt-4 overflow-y-hidden overflow-x-auto w-full sm:w-[100%] no-scrollbar cursor-grabbing"
          ref={scrollRef}
          onMouseDown={handleMouseDown}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
        >
          <div className="flex-shrink-0 ">
            <MiddleComponent />
          </div>
          <div className="flex-shrink-0">
            <RightComponent />
          </div>
          <div className="flex-shrink-0">
            <HashtagBanner />
          </div>
          <div className="w-2"></div>
        </div>
      </div>
      {subscriptionstatus === "loading" ? (
        <div className="mt-6 w-full ">
          <MySubscriptionsSkeleton />
        </div>
      ) : subscriptions.length > 0 ? (
        <div className="flex flex-col mb-4 w-full">
          <div className="flex flex-row items-center mt-6">
            <p className="text-white text-xl font-familjen-grotesk font-nomral">
              My Subscriptions
            </p>
            <div className="flex flex-row ">
              <p
                onClick={navigateToSubscriptions}
                className="text-viewAll ml-3 text-sm font-light font-familjen-grotesk cursor-pointer"
              >
                view all
              </p>
              <div className="text-viewAll ml-1 font-normal text-sm">-></div>
            </div>
          </div>
          <MySubscriptions profiles={subscriptions} />
        </div>
      ) : null}

      {categoriesstatus === "loading" ? (
        <div className=" w-full flex flex-col mt-4">
          {Array.from({ length: 3 }).map((_, index) => (
            <HomeCurationSkeleton key={index} />
          ))}
        </div>
      ) : categories.length > 0 ? (
        <div className="w-full ">
          {categories.map((category) => (
            <div key={category.category} className="flex flex-col">
              <div className="flex flex-row items-end mt-3">
                <p className="text-white text-xl font-familjen-grotesk font-normal">
                  {category.category}
                </p>
                <div className="flex flex-row">
                  <p
                    onClick={() => handleCategoryCurations(category.category)}
                    className="text-viewAll ml-3 mb-0.5 text-sm font-light font-inter cursor-pointer"
                  >
                    view all
                  </p>
                  <div className="text-viewAll ml-1 font-normal text-sm">
                    ->
                  </div>
                </div>
              </div>
              <Suspense fallback={<HomeCurationSkeleton />}>
                <CurationList
                  curations={category.curations}
                  category={category.category}
                />
              </Suspense>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default Home;
