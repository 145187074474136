import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBack from "../../../assets/icons/arrow_back.svg";
import CurationItemsSkeleton from "./../../skeleton/curationItemsSkeleton";
import { useDispatch, useSelector } from "react-redux";
import { fetchmySubscriptions } from "./../../../redux/slices/homeSlice";
import Linkify from "react-linkify";
import { setProfileEngagement } from "../../../redux/slices/profileEngagementSlice";
import {
  fetchProfile,
  selectProfileStatus,
  clearProfileData,
  toggleSubscription,
} from "./../../../redux/slices/profileSlice";
import ProfileSkeleton from "./../../skeleton/profileSkeleton";
import ProfileCarousel from "./../../Profile/Widgets/ProfileCarousel";
import Others from "../../../assets/icons/Subtract.svg";

import { domainUrl } from "./../../../utils/globals";
import ShareIcon from "../../../assets/icons/shareIcon.svg";
import ProfileIcon from "../../../assets/icons/profile.svg";
import SubscriptionProfileView from "./SubscriptionProfileView";
import useModal from "./../../hooks/ModalHook";

const SubscriptionsView = () => {
  const [isList, setIsList] = useState(true);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { username } = useParams();
  const [selectedProfile, setSelectedProfile] = useState({});
  const dispatch = useDispatch();
  const { subscriptions, subscriptionstatus } = useSelector(
    (state) => state.homeItems
  );

  const profileStatus = useSelector(selectProfileStatus);
  const profileData = useSelector((state) => state.profileData);
  const myData = useSelector((state) => state.myData);
  const isOwner = myData?.username === username;
  const { handleOpenModal } = useModal();

  const [isExpanded, setIsExpanded] = useState(false);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const maxLength = 350;

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const componentDecorator = (href, text, key) => (
    <a
      href={href}
      key={key}
      target="_blank"
      rel="noopener noreferrer"
      className="custom-link text-metaLink"
    >
      {text}
    </a>
  );
  // const ToggleList=(value)=>{
  //   setIsList(value);
  // }

  const handleNavigateHome = (value) => {
    navigate("/");
  };

  const handleItemClick = (profile) => {
    clearProfileData();
    setSelectedProfile(profile);
    dispatch(fetchProfile(profile.username));
  };

  const handleSubscription = () => {
    if (isLoggedIn) {
      dispatch(toggleSubscription(profileData));
    } else {
      handleOpenModal("modalLoginOpen");
    }
  };

  const handleManageSubscription = () => {
    navigate("/manage/mysubscriptions");
  };
  const openShareModal = (link) => {
    handleOpenModal("modalShareProfileOpen", link);
    dispatch(setProfileEngagement(profileData._id));
  };

  useEffect(() => {
    if (username) {
      dispatch(fetchmySubscriptions()).then((result) => {
        if (result.payload.length > 0) {
          const firstProfile = result.payload[0];
          setSelectedProfile(firstProfile);
          dispatch(fetchProfile(firstProfile.username));
        }
      });
    }
  }, [dispatch, username]);

  const hasImages = profileData.imageCards.length !== 0;
  const isSubscribed = profileData.subscribers?.includes(myData._id);

  return (
    <div className="flex flex-col w-full pr-2 xs:pr-8">
      <div
        className="flex flex-row items-center cursor-pointer -ml-2 mb-3"
        onClick={handleNavigateHome}
      >
        <img
          src={ArrowBack}
          alt="arrow-back"
          className="text-primary font-normal text-lg font-inter"
        />
        <p className="ml-2 text-lightText text-xs font-light font-inter">
          Back to Explore
        </p>
      </div>
      <div className="flex flex-row justify-between items-center">
        <p className="text-white text-xl sm:text-2xl font-familjen-grotesk font-normal tracking-wide ">
          My Subscriptions
        </p>
        <p
          className="text-lightText  text-xs font-normal font-inter cursor-pointer"
          onClick={handleManageSubscription}
        >
          Manage Subscriptions
        </p>
      </div>
      {isList ? (
        <>
          <div className="sm:hidden flex-col w-full">
            <div className="overflow-x-auto flex w-full mt-5 flex-row custom-scrollbar">
              {subscriptionstatus === "loading" ? (
                <CurationItemsSkeleton />
              ) : (
                subscriptions.map((item, index) => (
                  <div
                    key={item._id}
                    className={`flex flex-col xs:w-32 w-28 justify-center rounded-lg mr-3 px-2 py-2 flex-shrink-0 cursor-pointer ${
                      selectedProfile === item
                        ? " bg-chipBackground border border-borderColor"
                        : " bg-primaryBackground"
                    }`}
                    onClick={() => handleItemClick(item, index)}
                    style={{ flexShrink: 0 }}
                  >
                    {item.logo ? (
                      <img
                        src={item.logo}
                        alt="Curation"
                        className=" xs:w-24 xs:h-24 h-20 w-20px object-cover rounded-full flex-shrink-0"
                      />
                    ) : (
                      <img
                        src={ProfileIcon}
                        alt="Curation"
                        className=" xs:w-24 xs:h-24 w-20px h-20 p-2 object-cover rounded-full bg-dark flex-shrink-0"
                      />
                    )}
                    <span className=" w-full text-textColor mt-1 font-normal text-xs xs:text-sm md:text-xs truncate">
                      {item.name}
                    </span>
                  </div>
                ))
              )}
            </div>
            <div
              className={`w-[100%] my-4 border border-borderColor`}
              style={{ height: "1px" }}
            ></div>
            {profileStatus === "loading" ? (
              <ProfileSkeleton />
            ) : (
              <div className="mt-7 py-1 mr-3">
                <div
                  className={`${
                    isOwner || hasImages ? "md:bg-profileBackground" : ""
                  } bg-primaryBackground rounded-lg md:px-7 px-0  py-4 sm:py-8 w-full`}
                >
                  <div
                    className={`flex flex-col md:flex-row ${
                      isOwner || hasImages
                        ? "items-center md:justify-between justify-center"
                        : "items-center justify-center"
                    } `}
                  >
                    <div
                      className={`md:w-2/5 w-full flex flex-col ${
                        isOwner || hasImages
                          ? "md:justify-start md:items-start justify-center items-center"
                          : "justify-center items-center"
                      }  mb-4`}
                    >
                      {profileData.logo ? (
                        <img
                          src={profileData.logo}
                          alt="Profile"
                          className="rounded-full w-28 h-28 border border-white object-cover"
                          style={{ borderWidth: "3px" }}
                        />
                      ) : (
                        <img
                          src={ProfileIcon}
                          alt="Profile"
                          className="rounded-full w-28 h-28  bg-buttonBackground p-6  object-cover"
                        />
                      )}
                      <div
                        className={`mt-1 md:ml-1  ${
                          isOwner || hasImages ? "profile-text" : "text-center"
                        }`}
                      >
                        <p
                          className={`text-2xl  text-white font-normal font-familjen-grotesk`}
                        >
                          {profileData.name}
                        </p>
                        <p className="mt-1 text-xs  font-normal text-viewAll font-inter">
                          {profileData.username}.{domainUrl}
                        </p>
                        <p className="mt-1 text-xs  font-normal text-viewAll font-inter">
                          {profileData.subscribers.length} Subscribers
                        </p>

                        {
                          <Linkify componentDecorator={componentDecorator}>
                            <p className="mt-2 text-sm font-light text-textColor whitespace-pre-wrap overflow-hidden overflow-wrap break-word">
                              {isExpanded
                                ? profileData.description
                                : `${profileData.description.slice(
                                    0,
                                    maxLength
                                  )}${
                                    profileData.description.length > maxLength
                                      ? "..."
                                      : ""
                                  }`}
                              {profileData.description.length > maxLength && (
                                <span
                                  onClick={toggleReadMore}
                                  className="text-primary cursor-pointer ml-1"
                                >
                                  {isExpanded ? "<- Show Less" : "Read More ->"}
                                </span>
                              )}
                            </p>
                          </Linkify>
                        }
                        {(profileData.location || profileData.contact) && (
                          <p className="mt-2 text-xs text-viewAll">
                            {profileData.location}
                            {profileData.contact && profileData.location
                              ? " | "
                              : ""}
                            {profileData.contact}
                          </p>
                        )}
                        <div
                          className={`flex space-x-4 ${
                            hasImages
                              ? "md:justify-start justify-center"
                              : "justify-center"
                          }`}
                        >
                          {profileData.customText && profileData.customUrl && (
                            <a
                              href={profileData.customUrl}
                              className="px-5 mt-4  py-2 bg-primary text-buttonText text-sm rounded-lg"
                            >
                              {profileData.customText}
                            </a>
                          )}

                          {!isOwner && (
                            <button
                              className={`px-4 mt-4  py-2 ${
                                isSubscribed ? "bg-dark" : "bg-buttonBackground"
                              } text-primary text-sm rounded-lg`}
                              onClick={handleSubscription}
                            >
                              {isSubscribed ? "Subscribed" : "Subscribe"}
                            </button>
                          )}
                        </div>
                        <div
                          className={`flex ${
                            isOwner || hasImages
                              ? "md:justify-start justify-center"
                              : "justify-center"
                          }`}
                        >
                          <div
                            className="w-64 my-3  border border-borderColor "
                            style={{ height: "0.1px" }}
                          ></div>
                        </div>
                        <div
                          className={`flex space-x-4 ${
                            isOwner || hasImages
                              ? "justify-center md:justify-start"
                              : "justify-center"
                          }`}
                        >
                          <div
                            className="bg-primary rounded-full cursor-pointer "
                            onClick={() =>
                              openShareModal(
                                `https://` +
                                  profileData.username +
                                  `.${domainUrl}`
                              )
                            }
                          >
                            <img src={ShareIcon} alt="save" />
                          </div>
                          {profileData.links.map(
                            (link, index) =>
                              link.value && (
                                <a
                                  href={link.url + link.value}
                                  className="text-white cursor-pointer"
                                  key={index}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src={link.image}
                                    alt={link.title}
                                    className="w-8 h-8"
                                  />
                                </a>
                              )
                          )}
                          {profileData.otherLink && (
                            <a
                              href={profileData.otherLink}
                              className="text-white cursor-pointer"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={Others}
                                alt="other-link"
                                className="w-8 h-8"
                              />
                            </a>
                          )}
                        </div>
                      </div>
                    </div>

                    {hasImages ? (
                      <div
                        className={`flex md:ml-5 md:mt-0 mt-4 md:mb-0 justify-center ${
                          !profileData.description ||
                          profileData.description.length <= 40
                            ? "md:w-3/5 xl:w-1/2"
                            : "md:w-3/5 xl:w-3/5"
                        } h-full w-full md:justify-end mb-2`}
                      >
                        <ProfileCarousel images={profileData.imageCards} />
                      </div>
                    ) : null}
                  </div>
                </div>
                <SubscriptionProfileView gallery={false} owner={false} />
              </div>
            )}
          </div>
          <div className=" hidden sm:flex flex-row w-full h-full rounded-lg -ml-3">
            <div className="flex flex-col sm:ml-3 sm:w-2/5 md:w-1/3 lg:w-[30%] xl:w-1/5 rounded-lg">
              {/* <div className="flex flex-row items-center pl-1 pr-3 pt-1">
              <div className="relative flex-1">
                <FontAwesomeIcon icon={faSearch} className="absolute top-1/2 mb-2  transform -translate-y-1/2 text-textFieldColor w-3 h-3" />
                <input
                  type="text"
                  placeholder="Search"
                  className="pl-5 pr-3 py-3 rounded-lg text-white bg-primaryBackground focus:outline-none w-full font-inter font-normal text-sm flex items-center placeholder:text-textFieldColor placeholder:text-xs"
                />
              </div>
            </div> */}
              <div className="overflow-y-auto max-h-[calc(100vh-162px)] custom-scrollbar mt-3">
                {loading ? (
                  <CurationItemsSkeleton />
                ) : (
                  subscriptions.map((item, index) => (
                    <div
                      key={item._id}
                      className={`flex flex-col justify-start rounded-lg ml-1 pl-2.5 pr-2 cursor-pointer ${
                        selectedProfile === item
                          ? " bg-subscriptionBackground border border-primary"
                          : " bg-primaryBackground"
                      }`}
                      onClick={() => handleItemClick(item, index)}
                    >
                      <div className="flex items-center rounded-lg pt-2.5 pb-2.5 w-full">
                        {item.logo ? (
                          <img
                            src={item.logo}
                            alt="Curation"
                            className=" w-14 h-14 object-cover rounded-full flex-shrink-0"
                          />
                        ) : (
                          <img
                            src={ProfileIcon}
                            alt="Curation"
                            className=" w-14 h-14 p-2 object-con rounded-full bg-dark flex-shrink-0"
                          />
                        )}
                        <span className=" w-full text-white pl-3 font-normal text-sm truncate">
                          {item.name}
                        </span>
                      </div>
                      <div
                        className={`w-full  ${
                          selectedProfile === item
                            ? " "
                            : " border border-borderColor"
                        } `}
                        style={{ height: "1px" }}
                      ></div>
                    </div>
                  ))
                )}
              </div>
            </div>
            <div
              className="border mx-4 mt-4  border-borderColor"
              style={{ width: "0.1px" }}
            ></div>
            <div className="sm:w-3/5  mt-4 md:w-2/3 lg:w-[70%] xl:w-[77%] w-full">
              {profileStatus === "loading" ? (
                <ProfileSkeleton />
              ) : (
                <div className="">
                  <div
                    className={`${
                      hasImages ? "lg:bg-profileBackground" : ""
                    } bg-primaryBackground rounded-lg lg:px-7 px-0  py-4 sm:py-8 w-full`}
                  >
                    <div
                      className={`flex flex-col lg:flex-row ${
                        hasImages
                          ? "items-center md:justify-between justify-center"
                          : "items-center justify-center"
                      } `}
                    >
                      <div
                        className={`lg:w-1/2 w-full flex flex-col ${
                          hasImages
                            ? "lg:justify-start lg:items-start justify-center items-center"
                            : "justify-center items-center"
                        }  mb-4`}
                      >
                        {profileData.logo ? (
                          <img
                            src={profileData.logo}
                            alt="Profile"
                            className="rounded-full w-28 h-28 border border-white object-cover"
                            style={{ borderWidth: "3px" }}
                          />
                        ) : (
                          <img
                            src={ProfileIcon}
                            alt="Profile"
                            className="rounded-full w-28 h-28  bg-buttonBackground p-6  object-cover"
                          />
                        )}
                        <div
                          className={`mt-1 lg:ml-1  ${
                            hasImages ? "profile-text2" : "text-center"
                          }`}
                        >
                          <p
                            className={`text-2xl  text-white font-normal font-familjen-grotesk`}
                          >
                            {profileData.name}
                          </p>
                          <p className="mt-1 text-xs  font-normal text-viewAll font-inter">
                            {profileData.username}.{domainUrl}
                          </p>
                          <p className="mt-1 text-xs  font-normal text-viewAll font-inter">
                            {profileData.subscribers.length} Subscribers
                          </p>
                          <p className="mt-2 text-sm font-light text-textColor">
                            {profileData.description}
                          </p>
                          {(profileData.location || profileData.contact) && (
                            <p className="mt-2 text-xs text-viewAll">
                              {profileData.location}{" "}
                              {profileData.contact ? "|" : ""}{" "}
                              {profileData.contact}
                            </p>
                          )}
                          <div
                            className={`flex space-x-4 ${
                              hasImages
                                ? "lg:justify-start justify-center"
                                : "justify-center"
                            }`}
                          >
                            {profileData.customText &&
                              profileData.customUrl && (
                                <a
                                  href={profileData.customUrl}
                                  className="px-5 mt-4  py-2 bg-primary text-buttonText text-sm rounded-lg"
                                >
                                  {profileData.customText}
                                </a>
                              )}
                            <button
                              className={`px-4 mt-4  py-2 ${
                                isSubscribed ? "bg-dark" : "bg-buttonBackground"
                              } text-primary text-sm rounded-lg`}
                              onClick={handleSubscription}
                            >
                              {isSubscribed ? "Subscribed" : "Subscribe"}
                            </button>
                          </div>
                          <div
                            className={`flex ${
                              hasImages
                                ? "lg:justify-start justify-center"
                                : "justify-center"
                            }`}
                          >
                            <div
                              className="w-64 my-3  border border-borderColor "
                              style={{ height: "0.1px" }}
                            ></div>
                          </div>
                          <div
                            className={`flex space-x-4 ${
                              hasImages
                                ? "justify-center lg:justify-start"
                                : "justify-center"
                            }`}
                          >
                            <a
                              href={`https://${profileData.username}.${domainUrl}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bg-primary rounded-full cursor-pointer "
                            >
                              <img src={ShareIcon} alt="save" />
                            </a>
                            {profileData.links.map(
                              (link, index) =>
                                link.value && (
                                  <a
                                    href={link.url + link.value}
                                    className="text-white cursor-pointer"
                                    key={index}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={link.image}
                                      alt={link.title}
                                      className="w-8 h-8"
                                    />
                                  </a>
                                )
                            )}
                          </div>
                        </div>
                      </div>

                      {hasImages ? (
                        <div className="flex lg:ml-8 mt-5 justify-center h-full  lg:w-1/2 w-full lg:justify-end mb-2">
                          <ProfileCarousel images={profileData.imageCards} />
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="mt-4 w-full">
                    <SubscriptionProfileView gallery={false} owner={false} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default SubscriptionsView;
